import React, { useEffect } from "react"
import Layout from "../retech/components/layout/Layout"
import moment from 'moment'

import Article from "../retech/components/MediaCenterBlog/Articles/ArticleContent"
import {
    InputGroup,
    FormControl,
    Dropdown,
    DropdownButton,
} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

import { Helmet, Parent, Child } from "react-helmet"
import { Link, graphql } from "gatsby"

import Press from "../retech/components/MediaCenterBlog/Press/PressContent"
import Links from "../retech/components/MediaCenterBlog/Links/LinksContent"
export default function MediaCenterPage({ data }) {
    const [showOptions, setOptions] = React.useState("News")
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const urlactive = typeof window !== 'undefined' ? window.location.href.replace("/#", "/").split("/")[4] : '';

    const urlimage = typeof window !== 'undefined' ? window.location.hostname : '';
    const featurednews = data.featurednews.nodes;
    const allarticls = data.allarticls.nodes;
    const [searchTerm, setSearchTerm] = React.useState("");
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };


    useEffect(() => {
        setOptions(urlactive)
        if (urlactive === "Links" || urlactive === "News" || urlactive === "Article") {
            console.log("active")
        }
        else {
            document.querySelector('#News').click();
        }


    }, [])


    return (
        <Layout>
            <Helmet>
                <title>Media Center</title>

                <meta name="description" content={data.metadata.nodes[0].MediaCenterMetaDescription} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={data.metadata.nodes[0].MediaCenterMetaTitle} />
                <meta property="og:description" content={data.metadata.nodes[0].MediaCenterMetaDescription} />
                <meta property="og:image" content={urlimage + "/logo.svg"} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={url} />
                <meta property="twitter:title" content={data.metadata.nodes[0].MediaCenterMetaTitle} />
                <meta property="twitter:description" content={data.metadata.nodes[0].MediaCenterMetaDescription} />
                <meta property="twitter:image" content={urlimage + "/logo.svg"} />
            </Helmet>
            {/* <div className="container">
                <div className="col-md-12 py-5 pl-xs-0 pr-xs-0 pt-xs-0">
                    <div className="row">
                        {featurednews.map((node, index) => {
                            return (
                                <>
                                    <div className="col-md-7 border-bottom text-center pl-xs-0 pr-xs-0">

                                        <img
                                            src={node.FeaturedImage}
                                            alt="background media"
                                            className="w-100 blog-media"
                                        />
                                        <h3 className="font-weight-bold my-4">
                                            <a style={{ color: "#212529" }} href={'/mediacenters/' + node.CTALink}> {node.Title}  </a>
                                        </h3>
                                        <p className="product-par">

                                            {node.Intro}
                                        </p>
                                        <p className="product-par filter-name">
                                            <small className="text-muted">{moment(node.Date).format('MMM DD,YYYY')} | </small>{" "}
                                            <small className="font-weight-bold">NEWS 11</small>
                                        </p>

                                    </div>

                                </>
                            )
                        })}

                        <div className="col-md-5 border-left border-bottom d-none d-sm-block">
                            {allarticls.map((node, index) => {
                                return (
                                    <>
                                        <div className="row border-bottom pb-3 mb-3" key={index}>

                                            <div className="col-md-4 media-articls">
                                                <img src={node.Image} alt="News" className="w-100 right-category" />
                                            </div>
                                            <div className="col-md-8">
                                                <p className="product-par filter-name">
                                                    <small className="text-muted">{moment(node.Date).format('MMM DD,YYYY')} | </small>
                                                    <small className="font-weight-bold">Article</small>
                                                </p>
                                                <a style={{ color: "#212529" }} href={node.CTALink}>  <p className="font-weight-bold">
                                                    {node.Title}
                                                </p></a>
                                            </div>

                                        </div>
                                    </>
                                )
                            })}


                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container faq">
                <div className="col-md-12 py-5">
                    <div className="row">
                        <div className="col-md-4 d-block d-sm-none mb-5 dropdown-filter">
                            <DropdownButton
                                id="dropdown-filter"
                                title="Filter by"
                                variant="secondary"
                            >
                                <Dropdown.Item href="#/action-1">
                                    <InputGroup.Append className="shadow-none has-search">
                                        <FormControl
                                            placeholder="Search"
                                            aria-label="Search"
                                            aria-describedby="Search"
                                            className="shadow-none bg-white border-0"
                                            onChange={handleChange}
                                        />
                                        <span className=" form-control-feedback">
                                            <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                    </InputGroup.Append>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className={`${showOptions === "News" ? "active-filter" : ""
                                        }`}
                                    href="#"
                                    onClick={() => {
                                        setOptions("News")
                                    }}
                                >
                                    {" "}
                                    News
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className={`${showOptions === "Article" ? "active-filter" : ""
                                        }`}
                                    href="#"
                                    name="Article"
                                    onClick={() => {
                                        setOptions("Article")
                                    }}
                                >
                                    Article
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className={`${showOptions === "Links" ? "active-filter" : ""
                                        }`}
                                    href="#"
                                    onClick={() => {
                                        setOptions("Links")
                                    }}
                                >
                                    Links
                                </Dropdown.Item>
                            </DropdownButton>
                        </div>
                        <div className="col-md-7 d-none d-sm-block">
                            <div className="d-flex  filter-button justify-content-start">
                                <p className="font-weight-bold mr-2">Filter by </p>
                                <div
                                    className={`parallelogram mb-4 mr-3 ${showOptions === "News" ? "active-filter" : ""
                                        }`}
                                    style={{ width: "max-content" }}
                                    id={'News'}
                                    onClick={() => {
                                        setOptions("News")
                                    }}
                                >
                                    <Link className="text-dark">NEWS</Link>
                                </div>
                                <div
                                    className={`parallelogram mb-4 mr-3 ${showOptions === "Article" ? "active-filter" : ""
                                        }`}
                                    id={'Article'}
                                    onClick={() => {
                                        setOptions("Article")
                                    }}
                                >
                                    <Link className="text-dark">ARTICLES</Link>
                                </div>

                                <div
                                    className={`parallelogram mb-4  ${showOptions === "Links" ? "active-filter" : ""
                                        }`}
                                    id={'Links'}
                                    onClick={() => {
                                        setOptions("Links")
                                    }}
                                >
                                    <Link className="text-dark">LINKS</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 align-self-center search-loup mb-5 d-none d-sm-block">
                            <InputGroup.Append className="shadow-none has-search">
                                <FormControl
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="Search"
                                    className="shadow-none bg-light border-0"
                                    onChange={handleChange}
                                />
                                <span className="form-control-feedback">
                                    <FontAwesomeIcon icon={faSearch} />
                                </span>
                            </InputGroup.Append>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mb-5 pb-4 pt-xs-0 mt-xs-0">
                <div className={`col-md-12 ${showOptions !== "Article" && "d-none"}`}>
                    <Article search={searchTerm} setOptions={setOptions} showOptions={showOptions} />
                </div>


                {/*  {showOptions === "Press" ? ( */}
                <div className={`col-md-12 ${showOptions !== "News" && "d-none"}`}>
                    <Press search={searchTerm} setOptions={setOptions} showOptions={showOptions} />
                </div>
                {/*   ) : (
                    ""
                )} */}
                <div className={`col-md-12 ${showOptions !== "Links" && "d-none"}`}>
                    <Links search={searchTerm} setOptions={setOptions} showOptions={showOptions} />
                </div>

            </div>
        </Layout >
    )
}
export const query = graphql`
query {
    
   allarticls: allArticlesJson(limit: 3, sort: {fields: Date,order:DESC}) {
        nodes {
          Image
          Date
          Title
          CTALink
        }
      }
   featurednews: allNewsJson(filter: {Featured: {eq: true}}) {
        nodes {
          Featured
          CTALink
          Date
          Intro
          Title
          FeaturedImage
        }
      }
      metadata:allMetadataJson(filter: {MediaCenterMetaTitle: {ne: null}}) {
        nodes {
          MediaCenterMetaTitle
          MediaCenterMetaDescription
        }
      }
}
`

