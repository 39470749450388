import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'


import ReactPaginate from "react-paginate"
import JsonData from "./MOCK_DATA.json"
function Press({ search, setOptions, showOptions }) {
  const data = useStaticQuery(graphql`
  query  {
    allNewsJson(sort: {fields: Date, order: DESC}) {
      nodes {
        Category
        Image
        CTALink
       Date
        Intro
        Title
      }
    }
  }

    `)
  const [showPress, setPress] = useState(data.allNewsJson.nodes.slice(0, 50))
  const [pageNumber, setPageNumber] = useState(0)

  const usersPerPage = 8
  const pagesVisited = pageNumber * usersPerPage
  const [thisSearch, setThisSearch] = useState("")
  useEffect(() => {
    setThisSearch(search);

    var list = showPress
      .slice(pagesVisited, pagesVisited + usersPerPage).filter(x => x?.Title.includes(thisSearch))


    if (list?.length > 0 && showOptions !== "News") setOptions("News")

  }, [search])

  const displayUsers = showPress
    .slice(pagesVisited, pagesVisited + usersPerPage).filter(x => x?.Title.includes(thisSearch))
    .map(showPress => {
      return (
        <div className="Blog-article text-left mb-4">
          <div className="col-md-12 pl-xs-0 pr-xs-0">
            <div className="row">
              <div className="col-md-3 pl-xs-0 pr-xs-0">
                <img
                  src={showPress.Image}
                  alt="background image"
                  className="w-100"
                />
              </div>
              <div className="col-md-9 align-self-center pl-xs-0 pr-xs-0">
                <p className="product-par filter-name">
                  <small className="text-muted">{moment(showPress.Date).format('MMM DD,YYYY')}</small> |{" "}
                  {showPress.Category}
                </p>
                <a href={'/mediacenters/' + showPress.CTALink} className="text-dark">
                  <h4 className="font-weight-bold">{showPress.Title}</h4>
                </a>
                <p className="product-par">
                  {showPress.Intro}

                  <a href={'/mediacenters/' + showPress.CTALink}> Read Article</a>

                </p>
              </div>
            </div>
          </div>
        </div>
      )
    })

  const pageCount = Math.ceil(showPress.length / usersPerPage)

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  return (
    <div className="App">
      {displayUsers}
      <div className="col-md-12 px-0">
        <ReactPaginate
          previousLabel={""}
          nextLabel={""}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </div>
  )
}

export default Press
