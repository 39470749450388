import React, { useState, useEffect } from "react"
import ReactPaginate from "react-paginate"
import { Link, graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'

import JsonData from "./MOCK_DATA.json"
function Article({ search, setOptions, showOptions }) {
  const data = useStaticQuery(graphql`
  query  {
    allArticlesJson(sort: {fields: Date, order: DESC}){
      nodes {
        Category
        Image
        Date
        Title
        CTALink
        Intro
      }
    }
}
    `)
  const [showArticle, setArticle] = useState(data.allArticlesJson.nodes.slice(0, 50))
  const [pageNumber, setPageNumber] = useState(0)

  const usersPerPage = 8
  const pagesVisited = pageNumber * usersPerPage
  const [thisSearch, setThisSearch] = useState("")
  useEffect(() => {
    setThisSearch(search);

    var list = showArticle
      .slice(pagesVisited, pagesVisited + usersPerPage).filter(x => x?.Title.includes(thisSearch))



    if (list?.length > 0 && showOptions !== "Article") setOptions("Article")

  }, [search])

  const displayUsers = showArticle
    .slice(pagesVisited, pagesVisited + usersPerPage).filter(x => x?.Title.includes(thisSearch))
    .map(showArticle => {
      return (
        <>
          <div className="Blog-article text-left mb-4">
            <div className="col-md-12 pl-xs-0 pr-xs-0">
              <div className="row">
                <div className="col-md-3 pl-xs-0 pr-xs-0">
                  <img
                    src={showArticle.Image}
                    alt="background image"
                    className="w-100"
                  />
                </div>
                <div className="col-md-9 align-self-center pl-xs-0 pr-xs-0">
                  <p className="product-par filter-name">
                    <small className="text-muted">{moment(showArticle.Date).format('MMM DD,YYYY')}</small> |{" "}
                    {showArticle.Category}                </p>
                  <a href={showArticle.CTALink} target="_blank" className="text-dark">
                    <h4 className="font-weight-bold">{showArticle.Title}</h4>
                  </a>
                  <p className="product-par">
                    {showArticle.Intro}
                    <a href={showArticle.CTALink} target="_blank"> Read Article</a>

                  </p>
                </div>
              </div>
            </div>
          </div>
        </>


      )
    })


  const pageCount = Math.ceil(showArticle.length / usersPerPage)

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  return (
    <div className="App">
      {displayUsers}
      <div className="col-md-12 px-0">
        <ReactPaginate
          previousLabel={""}
          nextLabel={""}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </div>
  )
}

export default Article
